import withData from "../../lib/apollo"
import { gql } from "apollo-boost"
import { useQuery } from "@apollo/react-hooks"
import { Carousel } from "../../Components/Carousel"
import { Flex } from "../../Components/Flex"
import { Layout } from "../../Components/Layout"
import { useScroll, SnapItem } from "react-snaplist-carousel"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Picture, Spacer, Box } from "../../Components/"
import { Sans } from "../../Components/Typography"
import { space } from "../../helpers/space"
import { ProjectModal } from "../../Components/ProjectModal"
import { Media } from "../../Components/Responsive"
import { withRouter } from "next/router"

const HOME_QUERY = gql`
  query GetHome($limit: Int!, $offset: Int!) {
    allProjectAsset(
      sort: { _updatedAt: DESC }
      limit: $limit
      offset: $offset
      where: { displayOnHomepage: { eq: true } }
    ) {
      _id
      model
      alt
      pairedImagesModels
      imagePhotographer {
        _id
        hideName
        name
      }
      pairedAsset {
        _id
        model
        alt
        image {
          asset {
            _id
            url
            metadata {
              _key
              dimensions {
                _key
                aspectRatio
              }
            }
          }
        }
      }
      image {
        asset {
          _id
          url
          metadata {
            _key
            dimensions {
              _key
              aspectRatio
            }
          }
        }
      }
    }
  }
`

const DesktopImage = ({ setOpenModal, setProjectData, projectImage, pairedImagesModels, router }) => {
  const [desktopImageSize, setDesktopImageSize] = useState({ height: 0, width: 0 })
  const [hover, setHover] = useState(false)
  const imageSRC = projectImage?.image?.asset?.url || ""
  const imageID = projectImage?.image?.asset?._id
  const model = projectImage.model
  const photographerName = projectImage.imagePhotographer?.name
  const photographerID = projectImage.imagePhotographer?._id
  const hideName = projectImage.imagePhotographer?.hideName
  const imageAspectRatio = projectImage?.image?.asset?.metadata?.dimensions?.aspectRatio
  const resizedSRC = imageAspectRatio > 1 ? imageSRC + `?w=2000` : imageSRC + `?w=1500`

  const pairedAsset = projectImage?.pairedAsset
  const pairedAssetSRC = pairedAsset?.image?.asset?.url + `?w=1500`
  const pairedAssetID = pairedAsset?.image?.asset?._id
  const pairedModelName = pairedAsset?.model

  let title
  if (hideName) {
    title = photographerName
  } else if (!!pairedImagesModels && !!photographerName) {
    title = `${pairedImagesModels} by ${photographerName}`
  } else if (!!pairedImagesModels) {
    title = pairedImagesModels
  } else if (model && photographerName && pairedModelName && model !== pairedModelName) {
    title = `${model} & ${pairedModelName} by ${photographerName}`
  } else if (model && photographerName) {
    title = `${model} by ${photographerName}`
  } else if (model || photographerName) {
    title = model || photographerName
  }

  const updateWindowDimensions = () => {
    const windowWidth = window.innerWidth

    let height = window.innerHeight - 180
    let width = height * imageAspectRatio

    if (!!pairedAssetID && width + width + 48 >= windowWidth) {
      width = windowWidth / 2 - 40
      height = width / imageAspectRatio
    } else if (width + 32 >= windowWidth) {
      width = windowWidth - 32
      height = width / imageAspectRatio
    }

    setDesktopImageSize({ height, width })
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", updateWindowDimensions)
      updateWindowDimensions()
    }
    return () => window.removeEventListener("resize", updateWindowDimensions)
  }, [])

  return (
    <>
      <Flex
        flexDirection="row"
        flexWrap="nowrap"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ImageBackground
          style={{
            backgroundImage: `url(${resizedSRC})`,
            height: desktopImageSize.height + "px",
            width: desktopImageSize.width + "px",
            maxWidth: "100%",
          }}
          onClick={(e) => {
            e.stopPropagation()
            setProjectData({ photographerID, assetID: imageID })
            setOpenModal(true)
            router.push(`/?project=${photographerID}`, undefined, { shallow: true })
          }}
        />
        {!!pairedAssetID && (
          <ImageBackground
            style={{
              backgroundImage: `url(${pairedAssetSRC})`,
              height: desktopImageSize.height + "px",
              width: desktopImageSize.width + "px",
              maxWidth: "100%",
              marginLeft: "16px",
            }}
            onClick={(e) => {
              e.stopPropagation()
              setProjectData({ photographerID, assetID: pairedAssetID })
              setOpenModal(true)
              router.push(`/?project=${photographerID}`, undefined, { shallow: true })
            }}
          />
        )}
      </Flex>
      {!!title && (
        <TitleWrapper hover={hover}>
          <Spacer mb={2} />
          <Sans size="0" style={{ textTransform: "uppercase" }}>
            {title}
          </Sans>
        </TitleWrapper>
      )}
    </>
  )
}

const Home = withData(({ router }) => {
  const mobileContainer = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [projectData, setProjectData] = useState(null)
  const { data, fetchMore, loading } = useQuery(HOME_QUERY, {
    variables: {
      limit: 10,
      offset: 0,
    },
  })
  const snapList = useRef(null)

  useEffect(() => {
    if (!router.query.project && openModal) {
      setOpenModal(false)
    }
  }, [router.query.project])

  useEffect(() => {
    if (!openModal) {
      router.push("/", undefined, { shallow: true })
    }
  }, [openModal])

  let isFetchingMore = false

  const onFetchMore = () => {
    fetchMore({
      variables: {
        offset: data?.allProjectAsset?.length,
      },
      updateQuery: (prev: any, { fetchMoreResult }) => {
        if (!prev) {
          return []
        }

        if (!fetchMoreResult) {
          return prev
        }

        const firstMoreId = fetchMoreResult?.allProjectAsset?.[0]?._id
        const includes = !!prev.allProjectAsset.find((proj) => proj._id === firstMoreId)
        if (includes) {
          return prev
        }

        return Object.assign({}, prev, {
          allProjectAsset: [...prev.allProjectAsset, ...fetchMoreResult.allProjectAsset],
        })
      },
    })
  }

  useEffect(() => {
    const onMobileScroll = () => {
      if (!loading && window.scrollY + window.innerHeight >= mobileContainer?.current?.scrollHeight - 150) {
        onFetchMore()
      }
    }
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", onMobileScroll)
    }
    return () => window.removeEventListener("scroll", onMobileScroll)
  }, [data, onFetchMore, mobileContainer])

  const goToSnapItem = useScroll({ ref: snapList })
  const nextSlide = () => {
    if (nextDisabled) {
      return
    }
    goToSnapItem(currentIndex + 1)
    setCurrentIndex(currentIndex + 1)
  }
  const previousSlide = () => {
    goToSnapItem(currentIndex - 1)
    setCurrentIndex(currentIndex - 1)
  }

  if (currentIndex === data?.allProjectAsset?.length - 1 && !loading && !isFetchingMore) {
    isFetchingMore = true
    setTimeout(() => {
      if (!loading) {
        onFetchMore()
      }
      isFetchingMore = false
    }, 500)
  }

  const nextDisabled = currentIndex >= data?.allProjectAsset?.length - 1 && !loading

  const Mobile = () => {
    return (
      <Box px={1} pt="39px" pb="70px" style={{ display: openModal ? "none" : "block" }} ref={mobileContainer}>
        {data?.allProjectAsset.map((projectImage, index) => {
          const imageSRC = projectImage?.image?.asset?.url || ""
          const assetID = projectImage?.image?.asset?._id
          const photographerID = projectImage.imagePhotographer?._id

          const pairedAsset = projectImage?.pairedAsset
          const pairedAssetSRC = pairedAsset?.image?.asset?.url
          const pairedAssetID = pairedAsset?.image?.asset?._id
          const pairedAssetAlt = pairedAsset?.alt

          return (
            <Flex width="100%" key={imageSRC + "mobile"} flexDirection="column">
              <MobileImageWrapper width="100%" p={1}>
                <Picture
                  src={imageSRC}
                  alt={projectImage.alt}
                  width={1200}
                  onClick={(e) => {
                    e.stopPropagation()
                    setProjectData({ photographerID, assetID })
                    setOpenModal(true)
                    router.push(`/?project=${photographerID}`, undefined, { shallow: true })
                  }}
                />
              </MobileImageWrapper>
              {pairedAssetID && pairedAssetSRC && (
                <MobileImageWrapper width="100%" p={1}>
                  <Picture
                    src={pairedAssetSRC}
                    alt={pairedAssetAlt}
                    width={800}
                    onClick={(e) => {
                      e.stopPropagation()
                      setProjectData({ photographerID, assetID: pairedAssetID })
                      setOpenModal(true)
                      router.push(`/?project=${photographerID}`, undefined, { shallow: true })
                    }}
                  />
                </MobileImageWrapper>
              )}
            </Flex>
          )
        })}
      </Box>
    )
  }

  return (
    <>
      <Media greaterThan="sm">
        <Layout
          nextSlide={nextSlide}
          nextDisabled={nextDisabled}
          previousSlide={previousSlide}
          currentIndex={currentIndex}
        >
          <Carousel snapList={snapList}>
            {data?.allProjectAsset?.map((projectImage, index) => {
              const imageSRC = projectImage?.image?.asset?.url || ""

              return (
                <SnapItem width="100%" margin={{ right: space(1) + "px" }} snapAlign="center" key={imageSRC + index}>
                  <Box
                    onClick={(e) => {
                      e.stopPropagation()
                      nextSlide()
                    }}
                    style={{ cursor: nextDisabled ? "auto" : "e-resize" }}
                    width="100%"
                  >
                    <DesktopImage
                      router={router}
                      pairedImagesModels={data?.allProjectAsset?.pairedImagesModels}
                      projectImage={projectImage}
                      setOpenModal={setOpenModal}
                      setProjectData={setProjectData}
                    />
                  </Box>
                </SnapItem>
              )
            })}
          </Carousel>
        </Layout>
      </Media>
      <Media lessThan="md">
        <Layout currentIndex={currentIndex}>
          <Mobile />
        </Layout>
      </Media>
      <ProjectModal
        router={router}
        setProjectData={setProjectData}
        assetID={projectData?.assetID}
        photographerID={projectData?.photographerID}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  )
})

const MobileImageWrapper = styled(Box)`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ImageBackground = styled.div`
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  cursor: crosshair;
  display: block;
`

const TitleWrapper = styled.div<{ hover: boolean }>`
  opacity: ${(p) => (p.hover ? 1 : 0)};
`

export default withRouter(Home)
